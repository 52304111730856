* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    transition: all .2s ease;
    box-sizing: border-box;
}

html, body {
    &.noScroll {
        position: relative;
        overflow: hidden;
    }
}

html {
    height: 100%;
    font-size: $rem-baseline;
}

body {
    color: $main-text-color;
    line-height: 1.3;
    font-family: $font-raleway;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

svg {
    max-width: 100%;
    max-height: 100%;
}

@keyframes scrollDownPoint {
    0% {
        transform: translate(0, 0);
        opacity: 0; 
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0; 
    }
    100% {
        opacity: 0; 
    } 
}

.wrapper {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

#sliderMain {
    min-height: 1080px;

    * {
        transition: none;
    }
}

.color-blocks {
    position: absolute;
    right: 0;
    bottom: 200px;
    width: 180px;
    text-align: right;
    z-index: 20;
    overflow: hidden;

    &__item {
        height: 35px;
        display: inline-block;
    }

    &__red {
        width: 135px;
        background: $color-blocks-red;
    }

    &__gray { 
        background: $color-blocks-gray;
        width: 100%;
    }
    
    &__margin-top {
        margin-top: 35px;
    }
}

@mixin arr-pattern($width, $height, $arrowColor: $color-blocks-red) {
    width: $width;
    height: $height;
    background: repeating-linear-gradient(
        to right,
        transparent,
        transparent 2px,
        $arrowColor 2px,
        $arrowColor 4px
        );
}

@mixin matrix-bg($color: #e8e8e8) {
    position: relative;

    &:before, &:after {
        position: absolute;
        content: '';
        background-color: $color;
        z-index: 1;
    }

    &:before {
        top: 0;
        right: 0;
        width: 62%;
        height: 35px;
    }

    &:after {
        top: 35px;
        left: 0;
        width: 38%;
        height: calc(100% - 35px);
    }
}

.matrix-bottom-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 62.5%;
    height: 120px;
    background-color: #e8e8e8;
    z-index: 1;
    
    &--big {
        height: 230px;
    }
}

.arrows-pattern  {
    &--slider {
        position: absolute;
        left: 100px;
        bottom: 0;
        z-index: 20;
    }

    &--paralax {
        position: absolute;
        bottom: 0;
        left: 7.5rem;
        @include arr-pattern(50px, 380px);
    }   

    &--vertical {
        @include arr-pattern(50px, 440px);
    }
}

.section-separator {
    position: relative;
    height: 75px;
    display: flex;

    &--small {
        height: 35px;
    }

    &:before, &:after {
        position: absolute;
        content: '';
        top: 0;
    }

    &:before {
        left: 0;
        width: 38%;
        height: 100%;
        border-top: 1px solid $color-patter-gray;
    }
    
    &:after {
        left: 38%;
        @include arr-pattern(calc(62% - 30px), 100%, $color-patter-gray)
    }
}

.main-paralax {
    position: relative;
    height: 600px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    padding: 40px 70px;

    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.2);
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.menu-language {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-family: $font-roboto;
        font-weight: 100;
        color: $header-menu-icon-colors;

        &.active {
            color: $main-text-color;
            background-color: $header-menu-icon-colors;
        }
    }
}

.main-nav {
    display: flex;
    flex-flow: column;
    padding: 0 25px;
    width: 50%;

    &--secondary {

        .main-nav {

            &__label {
                font-size: 1.625rem;
                text-transform: initial;
            }

            &__expand {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__item {
        font-family: $font-raleway;
        margin-top: 3.4375rem;

        &:nth-child(2) {
            opacity: 0;
            transition: 0.3s opacity 200ms;
        }

        &:nth-child(3) {
            opacity: 0;
            transition: 0.3s opacity 400ms;
        }

        &:nth-child(4) {
            opacity: 0;
            transition: 0.3s opacity 600ms;
        }

        &:nth-child(5) {
            opacity: 0;
            transition: 0.3s opacity 800ms;
        }

        input {
            display: none;

            &:checked {
                & + span {
                    color: $header-menu-item-active-color;

                    i {

                        &:before {
                            background-color: $header-menu-item-active-color;
                        }

                        &:after {
                            opacity: 0;
                        }
                    }
                }

                & ~ div {
                    max-height: 300px;
                    transition: max-height 1.2s ease-in-out;
                }
            }
        }
    }

    &__label {
        color: $header-menu-item-color;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.875rem;
        display: inline-flex;
        align-items: center;
        transition: none;
    }

    &__expand {
        position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-left: 10px;

        &:before, &:after {
            position: absolute;
            content: '';
            background-color: $header-menu-item-color;
            transition: opacity .3s ease;
        }

        &:before {
            width: 100%;
            height: 2px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            width: 2px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__hidden-menu {
        max-height: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);

        &__item {
            font-size: 1.25rem;
            color: $header-menu-inner-item-color;
            margin: 20px 20px 0 0;
        }
    }
}

.breadcrumbs {
    overflow: hidden;

    .wrapper {
        position: relative;
        background-color: $breadcrumbs-bg-color;
        padding: 15px;

        &:after {
            position: absolute;
            content: '';
            height: 100%;
            top: 0;
            right: 0;
            width: 50%;
            margin-right: calc(50% - 50vw);
            background-color: $breadcrumbs-bg-color;
            z-index: 1;
        }
    }

    &__item {
        position: relative;
        font-family: $font-raleway;
        font-size: 0.75rem;
        color: $breadcrumbs-item-color;
        z-index: 2;

        &.active {
            color: $breadcrumbs-link-hover-color;
        }

        &:last-child:after {
            display: none;
        }

        &:after {
            content: '/';
        }
    }
}

.rev_slider_wrapper {
    z-index: 10;

    &__title {
        position: relative;
        font-family: $font-roboto;
        font-weight: 100;
        font-size: 4.6875rem;
        line-height: 1.1;
        text-align: center;
        text-transform: initial;
        padding: 0 20px 30px 20px;
        max-width: 100%;

        span {
            display: block;
            font-size: 3.75rem;
        }
    }

    &__text-layer {
        color: $main-slider-text-color !important;
        text-transform: uppercase;
    }

    &__scroll-down {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        width: 1.875rem;
        height: 3.125rem;
        border: 2px solid $main-slider-text-color;
        border-radius: 3.125rem;
        margin: auto;
        cursor: pointer;
        z-index: 20;
        display: none;

        &:before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: $main-slider-text-color;
            border-radius: 100%;
            animation: scrollDownPoint 2s infinite;
        }
    }

    .tp-caption {
        white-space: inherit !important;
    }

    .tparrows {
        width: 70px;
        height: 70px;
        background-color: transparent;
        display: none;

        &.tp-rightarrow {
            svg {
                transform: rotate(180deg);
            }
        }

        &:before {
            display: none;
        }

        svg {
            opacity: 0.75;
            width: 30px;
            fill: $main-slider-text-color;
        }
    }

    &--homepage {
        .tparrows {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .rev_slider_wrapper__scroll-down {
            display: block;
        }
    } 
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
   
    &.fixed {
        background-color: $header-sticky-background-color;
        padding: 8px 0;

        .header {
            &__logo {
                display: none;
            }

            &__mobile-logo {
                display: block;
                height: 45px;
            }

            &__button {
                width: 35px;
                height: auto;

                &--book {
                    margin-left: auto;
                    margin-right: 30px;
                }
                
                svg {
                    fill: $header-sticky-icons-color;
                    margin-bottom: 0;
                    height: 35px;
                    width: auto;
                }

                span {
                    display: none;
                }
            }
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
    }

    &__logo {
        max-width: 240px;
        text-align: center;
        padding: 10px 20px;
        background-color: $main-theme-color;
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.2)
    }

    &__mobile-logo {
        width: 65px;
        height: 50px;
        display: none;
    }

    &__button {
        width: 90px;
        height: 90px;
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        svg {
            width: 50px;
            height: 40px;
            fill: $main-icons-default-color;
            margin-bottom: 5px;
        }

        span {
            display: block;
            font: 1.0625rem $font-roboto;
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        background-color: rgba(255, 255, 255, 0.95);
        visibility: hidden;
        overflow-y:auto;
        opacity: 0;
        z-index: 20;

        &.active {
            opacity: 1;
            visibility: visible;

            .main-nav__item {
                opacity: 1;
            }
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 50px;
            border-bottom: 1px solid #c1c1c1;
        }

        &-close {
            width: 40px;
            height: 40px;
            cursor: pointer;
            
            svg {
                fill: $header-menu-icon-colors;
            }
        }

        &-nav {
            display: flex;
            justify-content: center;
        }

        &-logo {
            max-width: 235px;
        }
    }
}

.welcome {
    padding-top: 110px;
    @include matrix-bg();

    &__title, &__holder {
        position: relative;
        z-index: 2;
    }

    &__title {
        color: $main-theme-color;
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;

        span {
            text-transform: initial;
            color: $welcome-title-second-color;
            font-family: $font-roboto;
            font-weight: 700;
        }
    }

    &__holder {
        display: flex;
    }

    &__pattern {
        width: 45%;
        @include arr-pattern(45%, 385px, $color-patter-gray)
    }

    &__description {
        width: 55%;
        padding: 0 80px 20px 40px;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        color: $secondary-text-color;
        margin-top: 40px;

        a {
            color: $main-theme-color;
        }
    }
}

.promotions {
    padding: 55px 0;
    font-family: $font-raleway;
    font-size: 1.125rem;
    position: relative;
    display: flex;
    align-items: center;

    & + & {
        margin-top: 50px;
    }

    &--gray {
        background-color: $promotions-gray-background-color
    }

    &--image-left {
        .promotions {
            &__text {
                order: 2;
                text-align: left;

                &:before {
                    left: auto;
                    right: 0;
                }
            }

            &__image {
                text-align: right;
                padding: 0 0 0 30px;
            }

            &__bottom {
                justify-content: flex-start;
            }

            &__date {
                order: 2;
            }

            &__price {
                margin: 0 2.5rem 0 -25px;
                text-align: left;
            }
        }
    }

    &__type {
        font: 1.25rem $font-roboto;
        font-weight: 700;
        color: $promotions-type-color;
    }

    &__image {
        position: relative;
        width: 62%;
        text-align: left;
        padding-right: 30px;

        &-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255, 0.2);
            transition: opacity 400ms ease-in-out;
            opacity: 0;
            z-index: 1;
        }

        img {
            display: inline-block;
        }
    }

    &__text {
        position: relative;
        width: 38%;
        padding: 0 25px;
        text-align: right;
        animation-delay: 500ms !important;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50px;
            width: 6.5625rem;
            height: 35px;
            background-color: $promotions-akcent-color;
        }
    }

    &__title {
        font-size: 2.5rem;
        text-transform: uppercase;
        width: calc(100% - 4.6875rem);
        color: $promotions-akcent-color;
    }

    &__description {
        max-width: 400px;
        line-height: $paragraph-line-height;
        font-size: 1.25rem;
        color: $secondary-text-color;
    }

    &__title, &__description {
        margin-top: 20px;
        display: inline-block;
    }

    &__bottom {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        font-size: 1.5rem;
    }

    &__price {
        min-width: 12.5rem;
        margin: 0 -25px 0 2.5rem;
        padding: 5px 25px;
        text-align: right;
        color: $main-text-color;
        background-color: $promotions-price-background-color;

        span {
            font-size: 1rem;
            text-transform: uppercase;
            color: $promotions-price-currency-color;
        }
    }

    &__date {
        color: $promotions-akcent-color;
    }
}

.info-section {
    padding: 5.625rem 0;
    background-color: #f4f4f4;
    @include matrix-bg();

    .wrapper {
        position: relative;
        z-index: 2;
    }

    &__text {
        color: $secondary-text-color;
        font-size: 1.125rem;
        line-height: $paragraph-line-height;
        max-width: 800px;
        margin: 0 auto;
    }
}

.offers {
    padding: 100px 0;

    &--no-slider {
        padding-bottom: 0;

        .offers {

            &__holder {
                display: flex;
                flex-wrap: wrap;
            }

            &__item {
                width: 50%;
                margin: 0 0 7.5rem 0;

                &:nth-child(2n+1) {
                    padding-right: 3.125rem;
                }
                
                &:nth-child(2n+2) {
                    padding-left: 3.125rem;

                    .offers__image {
                        animation-delay: 600ms !important;
                    }
                }
            }
        }
    }

    .wrapper {
        position: relative;
    }
    
    &__item {
        margin: 0 45px;
        height: auto;
        color: $secondary-text-color;
    }

    &__image {
        position: relative;

        &:after { 
            content: '';
            width: 38%;
            height: 30px;
            background: $offers-image-label-color;
            display: block;
        }

        a { 
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(255, 255, 255, 0.2);
        }

        img {
            vertical-align:bottom;
        }
    }

    &__info {
        text-align: right;
        padding-right: 30px;
    }

    &__type {
       @extend .promotions__type;
       margin-top: 10px;
    }

    &__title {
       @extend .promotions__title;
       font-size: 1.625rem;
       margin-top: 30px;
       width: 100%;
    }

    &__desc {
       @extend .promotions__description;
       font-size: 1.25rem;
    }

    &__bottom {
        @extend .promotions__bottom;
    }

    &__price {
        font-size: 1.875rem;
        font-weight: 500;
        color: $offers-price-color;
        margin-right: 30px;
        
        span {
            color: $offers-price-currency-color;
            text-transform: uppercase;
            font-size: 1rem;
            margin-left: 5px;
        }
    }

    &__date {
        background: $offers-date-background-color;
        color: $main-text-color;
        min-width: 200px;
        margin-left: 30px;
        padding: 5px;
        text-align: center;
    }

    &__nav {
        position: absolute;
        top: 50%;
        left: -20px;
        width: calc(100% + 40px);
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        pointer-events: none;

        &-item {
            width: 40px;
            height: 30px;
            fill: #b1b1b1;
            cursor: pointer;
            pointer-events: all;

            &--next {
                transform: rotate(180deg);
            }
        }
    }

    .slick-track {
        display: flex;
    }
}

.banner {
    padding: 5.9375rem;

    &--akcent {
        background-color: $main-theme-color
    }

    &--secondary {
        background-color: $banner-bg-color;
    }

    &__image {
        position: relative;
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        font: 4.6875rem $font-roboto;
        font-weight: 100;
        z-index: 1;

        span {
            display: block;
            font-size: 3.75rem;
        }
    }
}

.gallery-block {
    padding: 25px;
}

.page-nav {
    background-color: $page-nav-bg-color;
    padding: 1.875rem 0;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 20px;
        font-family: $font-roboto;
        font-weight: 700;
        color: $page-nav-title-color;
    }

    &__icons {
        display: flex;
        align-items: center;
    }

    &__item {
        width: 45px;
        height: 45px;
        margin: 0 10px;

        &--right svg {
            transform: rotate(180deg);
        }

        svg {
            fill: $page-nav-icons-color;
            stroke: $page-nav-icons-color;
        }
    }
}

.inner-info {
    &--spacer {
        &:after {
            content: '';
            display: block;
            height: 50px;
            background-color: $breadcrumbs-bg-color;
        }
    }

    &--solid .inner-info__top:before {
        background: $breadcrumbs-bg-color;
    }

    &--right-pattern {
        background: $breadcrumbs-bg-color;
        overflow: hidden;

        .inner-info__top {
            background-color: #fff;
            
            &:before {
                left: auto;
                right: 0;
                margin-left: auto;
                margin-right: -50vw;
            }
        }
    }

    &__top {
        position: relative;
        padding: 4.375rem 15px;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            @include arr-pattern(50vw, 100%, $color-patter-gray);
            margin-left: -50vw;
        }
    }

    &__title {
        font-size: 2.5rem;
        text-transform: uppercase;
        margin-bottom: 35px;
        color: $inner-info-title-color;
    }

    &__text {
        color: $secondary-text-color;
        font-size: 1.25rem;
        line-height: 1.5;
    }

    &__bottom {
        background: $inner-info-bottom-info-bg-color;
        padding: 2.5rem 0;
        font-family: $font-raleway;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__price {
        margin-left: 0;
        font-size: 1.5rem;
        display: inline-block;
        white-space: nowrap;
        text-align: center;
    }

    &__date {
        font-size: 1.5rem;
        font-weight: 500;
        padding-right: 30px;
        color: $inner-info-bottom-date-color;
    }

    &__buttons-holder {
        display: flex;
        align-items: center;
    }

    &__button {
        color: $inner-info-button-text-color;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            text-align: center;
            display: block;
            margin-top: 5px;
            font-family: $font-roboto;
            font-weight: 100;
        }

        svg {
            width: 50px;
            height: 40px;
            fill: $inner-info-button-text-color;
        }

        &--book {
            margin-left: 35px;
        }

        &--panorama svg {
            width: 70px;
        }
    }

    &__additional-info {
        background-color: $inner-info-additional-info-bg-color;
        color: $inner-info-additional-info-text-color;
        padding: 25px 0;

        article {
            margin-bottom: 50px;
            font-size: 20px;
        }

        h2,h3,h4,h5,h6 {
            color: $inner-info-title-color;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 26px;
        }

        h3 {
            font-size: 20px;
        }

        h4 {
            font-size: 18px;
        }
    }
}

.gallery {

    &__block {
        padding: 3.125rem 0;

        &--gray {
            background-color: $gallery-block-gray-color;
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__item {
        margin-bottom: 2.5rem;
        width: 50%;

        &:nth-child(2n+1) {
            padding-right: 2.8125rem;
        }

        &:nth-child(2n+2) {
            padding-left: 2.8125rem;
        }
        
        &--top-space {
            margin-top: 2.5rem;
            animation-delay: 500ms !important;
        }
    }

    &__figure {
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(255,255,255, 0.2);
            transition: opacity 0.3s ease;
            opacity: 0;
            z-index: 1;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%,-50%);
        }
    }

    &__count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 9.375rem;
        font-weight: 100;
        z-index: 2;
        opacity: 0;
    }

    &__title {
        color: $gallery-title-color;
        padding: 0 1.875rem;
        font-size: 1.6875rem;
        font-weight: 500;
        margin-top: 10px;
        text-transform: uppercase;
    }
}

.footer {
    font-family: $font-raleway;
    font-weight: 500;
    
    &__top {
        background-color: $footer-top-background-color;
        padding: 45px 0;
        position: relative;

        .wrapper {
            position: relative;
            z-index: 2;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include arr-pattern(100%, 100%, #a41b32)
            z-index: 1;
        }
    }

    &__bottom {
        padding: 1.5625rem 0;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: center;

        &-item {
            text-transform: uppercase;
            margin: 5px 15px;
            color: $footer-top-nav-color;

            &.active {
			    color: $footer-top-nav-hover-color;
            }
        }
    }
    
    &__info {
        text-align: center;
        margin: 30px 0;

        &-logo {
            max-width: 160px;
            height: 110px;
            display: inline-block;
        }

        &-text {
            font-size: 1.125rem;
            line-height: 1.5;
        }
    }
   
    &__allrights {
        color: $footer-bottom-text-color;
        margin-left: auto;
        margin-right: auto;

        a {
            color: $footer-bottom-link-color;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
            width: 35px;
            height: 25px;
            margin: 0 5px;

            svg {
                fill: $footer-social-color;
            }
        }
    }
}

.contacts {
    background-color: $contacts-bg-color;
    padding: 3.125rem 0;
    overflow: hidden;

    &--no-top-padding {
        padding-top: 0;
    }
    
    &__wrapper {
        position: relative;
        background-color:$contacts-inner-bg-color;
        padding-top: 5rem;
        padding-bottom: 5rem;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 100%;
            height: 100%;
            width: 50%;
            @include arr-pattern(50%, 100%, $color-patter-gray);
        }
    }

    &__title, &__form, &__buttons {
        max-width: 860px;
    }

    &__title {
        font-family: $font-raleway;
        font-size: 2.5rem;
        color: $contacts-text-title-color;
        text-transform: uppercase;
    }

    &__form {
        margin: 40px 0 0 -30px;
    }

    &__buttons {
        text-align: center;
        margin-top: 80px;
    }
}

.form {

    &__row {
        margin-top: 15px;
        font-family: $font-raleway;
        font-size: 0.9375rem;

        &--two-columns {
            display: flex;
            justify-content: space-between;
        }

        &--center {
            align-items: center;
        }

        &--mt-40 {
            margin-top: 2.5rem;
        }

        &--no-margin {
            margin: 0;
        }

        &--buttons {
            padding: 0 15px 0 30px;
        }
    }

    &__col {
        padding: 0 15px;

        &-50{ 
            width: 50%;
        }
    }

    &__label {
        color: $secondary-text-color;
    }

    &__input {
        padding: 10px 15px;
        min-height: 45px;
    }

    &__textarea {
        padding: 15px;
        min-height: 180px;
        resize: vertical;
    }

    &__input, &__textarea {
        display: block;
        width: 100%;
        border-radius: 0;
        background: $contacts-bg-color;
        border: none;
        color: $secondary-text-color;
    }

    &__checkbox {
        display: none;

        & + .form__label {
            position: relative;
            padding-left: 45px;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-height: 30px;
            
            &:before {
                position: absolute;
                content: '';
                left: 0;
                width: 25px;
                height: 25px;
                border: 1px solid $secondary-text-color;
            }

            &:after {
                position: absolute;
                content: '\2714';
                left: 8px;
                top: 7px;
                display: none;
            }
        }   

        &:checked + .form__label:after {
            display: block;
        }
    }
}

.btn {
    padding: 15px 35px;
    font-family: $font-raleway;
    font-size: 1.5rem;
    background-color: $btn-main-bg-color;
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: none;
    color: #fff;
    min-width: 170px;
    border-radius: 0;
    -webkit-appearance: none;
}