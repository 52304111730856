$font-raleway: 'Raleway', sans-serif;
$font-roboto: 'Roboto', sans-serif;

$rem-baseline: 16px;

$paragraph-font-size: 1.25rem;
$paragraph-line-height: 1.5;

$main-slider-text-color: #fff;
$main-theme-color: #bf3d49;
$main-text-color: #fff;
$secondary-text-color: #414141;
$main-icons-default-color: #fff;

$color-blocks-red: #bf3d49;
$color-blocks-gray: #9a928d;
$color-patter-gray: #b0afaf;


$breadcrumbs-bg-color: #e5e5e5;
$breadcrumbs-item-color: #7c7c7c;
$breadcrumbs-link-hover-color: #c02640;


$header-sticky-background-color: #fff;
$header-sticky-icons-color: #5e5e5e;
$header-menu-icon-colors: #c02640;
$header-menu-item-color: #5e5e5e;
$header-menu-item-active-color: #c02640;
$header-menu-inner-item-color: #727272;


$welcome-title-second-color: #555555;
$banner-bg-color: #9a9a9a;


$promotions-gray-background-color: #f4f4f4;
$promotions-type-color: #555;
$promotions-akcent-color: #c02640;
$promotions-price-background-color: #a0a0a0;
$promotions-price-currency-color: #555454;

$offers-image-label-color: #c02640;
$offers-price-color: #c02640;
$offers-price-currency-color: #7a0d20;
$offers-date-background-color: #a0a0a0;


$inner-info-title-color: #c02640;
$inner-info-bottom-info-bg-color: #f1f1f1;
$inner-info-bottom-date-color: #c02640;
$inner-info-button-text-color: #9d9d9d;
$inner-info-button-text-hover-color: #c02640;
$inner-info-additional-info-bg-color: #f1f1f1;
$inner-info-additional-info-text-color: #414141;

$page-nav-bg-color: #cecece;
$page-nav-title-color: #555555;
$page-nav-title-hover-color: #c02640;
$page-nav-icons-color: #9d9d9d;
$page-nav-icons-hover-color: #c02640;

$gallery-block-gray-color: #f4f4f4;
$gallery-title-color: #c02640;
$gallery-title-hover-color: #414141;

$footer-top-background-color: #c02640;
$footer-top-nav-color: #bebebe;
$footer-top-nav-hover-color: #fff;
$footer-bottom-text-color: #915d62;
$footer-bottom-link-color: #8b8b8b;
$footer-social-color: #915d62;


$contacts-bg-color: #f1f1f1;
$contacts-inner-bg-color: #fff;
$contacts-text-title-color: #c02640;

$btn-main-bg-color: #979797;

$grid-breakpoints: (
    sm: 480px,
    md: 767px,
    lg: 992px,
    tablet: 1024px,
    xl: 1200px
);