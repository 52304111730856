@media (min-width: map-get($grid-breakpoints, tablet)) {
	.rev_slider_wrapper {

		.tparrows:hover {
			svg {
				opacity: 1;
			}
		}

		.tp-caption {
			min-width: 1000px !important;
		}
	}

	.header__button:hover {
		background-color: $main-theme-color;
	}

	.header.fixed .header__button:hover {
		background: transparent;

		svg {
			fill: $main-theme-color;
		}
	}

	.menu-language__item:hover {
		color: $main-text-color;
		background-color: $header-menu-icon-colors;
	}

	.promotions {
		&__image-link:hover {
			opacity: 1;
		}

		&__type:hover {
			color: $promotions-akcent-color;
		}

		&__title:hover {
			color: $promotions-type-color;
		}
	}

	.offers {
		&__image:hover a {
			opacity: 1;
		}

		&__nav-item:hover {
			fill: $main-theme-color;
		}

		&--gallery .offers__image a:hover {
			opacity: 1;
		}
	}

	.footer {

		&__navigation-item:hover {
			color: $footer-top-nav-hover-color;
		}

		&__social-item:hover svg {
			fill: $main-theme-color;
		}

		&__allrights a:hover {
			color: $main-theme-color;
		}
	}

	.main-nav {

		&__hidden-menu__item:hover {
			color: $header-menu-item-active-color;
		}

		&__label:hover {
			color: $header-menu-item-active-color;

			i:before,
			i:after {
				background-color: $header-menu-item-active-color;
			}
		}
	}

	.breadcrumbs__item:hover {
		color: $breadcrumbs-link-hover-color;
	}

	.inner-info {
		&__button:hover {
			color: $inner-info-button-text-hover-color;

			svg {
				fill: $inner-info-button-text-hover-color;
			}
		}
	}

	.page-nav {

		&__title:hover {
			color: $page-nav-title-hover-color;
		}

		&__item:hover svg {
			fill:$page-nav-icons-hover-color;
			stroke:$page-nav-icons-hover-color;
		}
	}

	.gallery__item:hover {

		.gallery {
			&__figure {

				&:before {
					opacity: 1;
				}

				img {
					transform: translate(-50%,-50%) scale(1.15);
				}
			}

			&__count {
				opacity: 1;
			}

			&__title {
				color: $gallery-title-hover-color;
			}
		}
	}

	.btn:hover {
		background-color: $main-theme-color;
	}
}

@media (max-width: map-get($grid-breakpoints, tablet)) {
	html {
		font-size: 15px;
	}

	.header {
		&__logo {
			max-width: 200px;
		}

		&__button {
			width: 75px;
			height: 75px;

			svg {
				width: 35px;
				height: 30px;
			}
		}

		&__inner {
			padding: 0 20px;
		}

		&__menu-top {
			padding: 10px 20px;
		}

		&__menu-logo {
			max-width: 200px;
		}
	}

	.offers__nav {
		left: 0;
		width: 100%;
	}

	.form {

		&__input,
		&__textarea {
			-webkit-appearance: none;
		}
	}
}


@media (max-width: map-get($grid-breakpoints, lg)) {
	.wrapper {
		padding-left: 25px;
		padding-right: 25px;
	}

	.arrows-pattern--paralax {
		left: 4rem;
	}

	.header__logo {
		max-width: 150px;
		height: 110px;
	}

	.section-separator {
		height: 65px;

		&:before {
			width: 50%;
		}

		&:after {
			left: 50%;
			width: 50%;
		}
	}

	.banner {

		&__text {
			font-size: 55px;

			span {
				font-size: 34px;
			}
		}
	}

	.rev_slider_wrapper .tp-caption {
		min-width: 750px !important;
	}

	.breadcrumbs .wrapper {
		padding-left: 25px;
		padding-right: 25px;
	}

	.welcome {

		&__pattern {
			width: 40%;
		}

		&__description {
			padding: 20px;
			margin: 0;
		}
	}

	.promotions {
		padding: 40px 0;

		&__text {
			width: 50%;

			&:before {
				width: 2.8125rem;
			}
		}

		&__image {
			width: 50%;
		}

		&__title {
			font-size: 24px;
			width: calc(100% - 2.8125rem)
		}
	}

	.offers {
		padding: 60px 0;

		&__item {
			margin: 0 25px;
		}

		&__info {
			padding-right: 10px;
		}

		&__title {
			font-size: 20px;
		}

		&__date {
			min-width: 100px;
			padding: 5px 15px;
		}
	}

	.contacts__form {
		margin: 25px 0 0 -15px;
	}

	.form__row--buttons {
		padding: 0 15px;
	}

	.main-paralax {
		padding-left: 55px;
		padding-right: 55px;
	}

	.footer {

		&__info {
			padding-right: 20px;
			flex: 1 0 350px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, md)) {

	.section-separator {
		height: 55px;
	}

	.color-blocks {
		width: 120px;
		bottom: 100px;

		&__red {
			width: 50px;
		}

		&__margin-top {
			margin-top: 30px;
		}
	}

	.arrows-pattern {
		&--slider {
			left: 40px;
			top: 0;
			bottom: auto;
			animation: none !important;
		}

		&--vertical {
			height: 200px;
			width: 40px;
		}

		&--paralax {
			left: 10px;
			height: 190px;
		}
	}

	.menu-language__item {
		width: 40px;
		height: 40px;
	}

	.main-nav {
		width: 100%;

		&--secondary .main-nav__label {
			font-size: 20px;	
		}

		&__expand {
			width: 18px;
			height: 18px;
		}

		&__hidden-menu {
			justify-content: center;
		}

		&__item {
			text-align: center;
			margin-top: 20px;
		}

		&__label {
			font-size: 22px;
		}
	}

	.rev_slider_wrapper {

		&__title {
			font-size: 35px;

			span {
				font-size: 24px;
			}
		}

		&__scroll-down {
			bottom: 25px;
		}

		.tparrows {
			width: 40px;
			height: 40px;
			opacity: 1 !important;
			visibility: visible !important;
			top: auto !important;
			bottom: 25px;
			transform: translateX(-50%) !important;

			&.tp-leftarrow {
				margin-left: 40px;
			}

			&.tp-rightarrow {
				margin-left: -40px;
			}
		}

		.tp-caption {
			min-width: 300px !important;
		}
	}

	.header {

		&__inner {
			display: none;
		}

		&.fixed .header {

			&__inner {
				padding: 0 15px;
				display: flex;
			}

			&__mobile-logo {
				width: 55px;
				height: 40px;
			}

			&__button--book {
				margin-right: 20px;
			}
		}

		&__menu {

			&-logo {
				max-width: 100px;
				height: 60px;
			}

			&-close {
				width: 30px;
				height: 30px;
			}

			&-nav {
				flex-direction: column;
			}
		}
	}

	.welcome {
		padding: 50px 25px;

		&__title {
			font-size: 25px;
			text-align: left;
			margin-bottom: 20px;
		}
		
		&__pattern {
			display: none;
		}

		&__description {
			width: 100%;
			margin: 0;
			padding: 0;
			font-size: 16px;
			line-height: 1.5;
		}
	}

	.banner {
		padding: 30px;

		&__text {
			font-size: 42px;

			span {
				font-size: 28px;
			}
		}
	}

	.main-paralax {
		padding-left: 20px;
		padding-right: 20px;
		height: 540px;
	}

	.promotions {
		padding: 25px 0 30px 0;
		flex-direction: column;

		&--image-left {

			.promotions {

				&__bottom {
					margin: 35px 0 0 0;
				}

				&__price {
					// margin-left: 0;
				}

				&__image {
					padding: 0 20px 0 0;
				}
			}
		}

		&__type {
			margin-top: 30px;
			font-size: 14px;
		}

		&__title {
			font-size: 25px;
			margin-top: 15px;
		}

		&__description {
			font-size: 16px;
		}

		&__holder {
			flex-flow: column;
		}

		&__image {
			width: 100%;
			padding: 0 0 0 20px;
		}

		&__text {
			width: 100%;
			order: 2;

			&:before {
				top: 60px;
			}
		}

		&__bottom {
			margin: 35px 0 0 0;
		}

		&__price {
			min-width: 150px;
			font-size: 22px;
			// margin-right: 0;
		}

		&__date {
			font-size: 22px;

			span {
				font-size: 14px;
			}
		}
	}

	.offers {
		padding: 25px 0;

		&--no-slider {
			.offers__item {
				width: 100%;
                margin: 0 0 60px 0;

				&:nth-child(2n+1) {
                    padding-right: 0;
                }

                &:nth-child(2n+2) {
                    padding-left: 0;
                }
			}
		}

		&__item {
			margin: 0;
		}

		&__image:after {
			width: 63%;
		}

		&__type {
			margin-top: 10px;
		}

		&__title {
			width: 100%;
		}

		&__info {
			padding-right: 0;
		}	

		&__bottom {
			width: 100%;
		}

		&__price {
			font-size: 22px;
			margin: 0;
		}

		&__date {
			margin-left: 25px;
			font-size: 22px;

			span {
				font-size: 14px;
			}
		}

		

		&__nav {
			position: static;
			transform: none;
			margin-top: 35px;

			&-item {
				width: 55px;
				margin: 0 30px;
			}
		}
	}

	.footer {

		&__top {
			padding: 25px 0 30px 0;
		}

		&__info {
			flex: 1 1 100%;
			border-right: none;
			padding-right: 0;
			margin: 0;

			&-logo {
				max-width: 160px;
				margin: 0 auto;
			}

			&-text {
				text-align: center;
				font-size: 14px;
			}
		}

		&__navigation {
			display: none;
		}

		&__bottom {
			padding: 20px 0 25px 0;

			.wrapper {
				flex-flow: column;
			}
		}

		&__social-item {
			width: 40px;
			height: 30px;
			margin: 0 10px;
		}

		&__allrights {
			margin-top: 20px;
			padding-right: 0;
			order: 2;
			font-size: 14px;
			line-height: 1.5;
			text-align: center;

			span {
				display: block;
			}
		}
	}

	.page-nav {

		&__item {
			width: 35px;
			height: 35px;
		}
	}

	.inner-info {
		&__top {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}

		&__title {
			font-size: 25px;
			margin-bottom: 15px;
		}

		&__bottom .wrapper {
			flex-flow: column;
		}

		&__date {
			font-size: 1.2rem;
		}

		&__price {
			margin: 0 0 30px 0;
		}
	}

	.gallery {

		&__block {
			padding: 25px 0;
		}

		&__wrapper {
			flex-wrap: wrap;
		}

		&__item {
			width: 100%;

			&--top-space {
				margin-top: 0;
			}

			&:nth-child(2n+1) {
				padding-right: 0;
			}

			&:nth-child(2n+2) {
				padding-left: 0;
			}
		}

		&__title {
			text-align: center;
			font-size: 1.2rem;
			padding: 0;
		}
	}

	.info-section {
		padding: 50px 0;
	}

	.contacts {

		&__wrapper {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		&__form {
			margin: 25px 0 0 0;
		}

		&__title {
			font-size: 2rem;
		}

		&__text {
			margin-top: 25px;
		}
	}

	.form {

		&__row {

			&--two-columns {
				display: block;
			}

			&--buttons {
				padding-left: 0;
			}

			.btn, .gcaptcha {
				margin-top: 20px;
			}
		}

		&__col {
			padding: 0;

			&-50 {
				width: 100%;

				&+& {
					margin-top: 15px;
				}
			}
		}
	}

	.btn {
		width: 100%;
		padding: 20px;
		font-size: 1.5rem;
	}

	.menu-language {

		&__item {
			width: 50px;
			height: 50px;
			font-size: 18px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, sm)) {
	.inner-info {
		&__price {
			margin-top: 15px;
		}
		
		&__info {
			text-align: center;
		}
	}
}